const loadingRequests = [
  "/card/web/insuranceCompany/getInsuranceCompany",
  "/order/web/bookedInfoQuery/listBookedInfoQueryPage",
  "/order/web/bookedInfoQuery/queryBookedInfoDetailById",
  "/order/web/bookedInfoQuery/getStoreListByCompanyType",
  "/card/web/cardQuery/listCardQueryPage",
  "/card/web/cardQuery/queryCardDetailById",
  "/card/web/cardlog/selectInsuranceCardLog",
  "/order/web/claim/listClaimImportRecordDetailPage",
  "/order/web/claim/exportClaimImportRecord",
  "/order/web/claim/exportClaimImportRecordSplitAmount",
  "/order/web/claim/listClaimOrderPage",
  "/order/web/claim/getClaimOrderDetailById",
  "/order/web/claim/exportClaimOrder",
  "/order/web/logOrder/queryLogClaimByList",
  "/card/web/customerInfo/listCustomerInfoPage",
  "/card/web/importHead/getFilelUrl",
  "/card/web/insuranceCustomerRelative/listInsuranceCustomerRelativePage",
  "/card/web/insuranceCustomerRelative/exportInsuranceCustomerRelative",
  "/system/web/role/getList",
  "/card/web/importHead/getImportHead",
  "/store/web/medicalStore/getStoreListByStrategyPage",
  "/card/web/insuranceCompany/getInsuranceCompany",
  "/card/web/cardlog/getCardLog",
  "/system/web/log/getLoginLogPageList",
  "/system/web/log/getOptPageLog",
  "/store/web/commodityClassification/listCommodityClassificationPage",
  "/store/web/commodityClassification/exportCommodityClassification",
  "/store/web/store/accountCompany/list",
  "/store/web/store/accountCompany/view",
  "/store/web/store/accountCompany/queryAccountCompanyFinanceById",
  "/system/web/excelTemplateDownload/getExcelUrl",
  "/store/web/storeEvaluate/listStoreEvaluatePage",
  "/store/web/medicalStore/selectMedicalStorePage",
  "/store/web/medicalStore/selectMedicalStoreById",
  "/store/web/medicalStore/exportMedicalStore",
  "/store/web/medicalStore/selectMedicalStoreTest",
  "/store/web/medicalStore/exportMedicalStoreTest",
  "/store/web/medicalStore/exportMedicalStoreTestDetail",
  "/store/web/excelTemplateDownload/getExcelUrl'",
  "/store/web/commodity-white-list/queryPageList",
  "/order/web/orderQuery/listQjfCustomerOrderQueryPage",
  "/order/web/orderQuery/queryOrderDetailById",
  "/order/web/orderQuery/listQjfOrderQueryPage",
  "/order/web/orderQuery/exportInsuranceCompanyOrderDetail",
  "/order/web/orderQuery/exportInsuranceCompanyOrderSummary",
  "/account/web/insuranceSide/getPageInfo",
  "/account/web/bookedInfoQuery/queryBookedInfoDetailById",
  "/account/web/insuranceSide/listGlobalSettlementPage",
  "/account/web/insuranceSide/exportGlobalSettlementExcel",
  "/account/web/insuranceSide/downloadZipUrl",
  "/order/web/cardStatistics/cardConsumption",
  "/order/web/dailyConsumption/queryDailyConsumptionList",
  "/order/web/dailyConsumption/exportDailyConsumptionList",
  "/order/web/dailyConsumption/exportDailyConsumptionDetailList",
  "/order/web/statistics/list",
  "/order/web/statistics/exportExcel",
  "/order/web/statistics/exportExcelDetail",
  "/order/web/claim/personalClaim",//理赔列表 - 单个理赔
  "/card/web/company-open-card-report/companyOpenCardReportPageList",
  "/card/web/company-open-card-report/exportCompanyOpenCardReport",
  "/card/web/company-open-card-report/exportOpenCardReportActivationDetail",
  "/system/web/ad/listAdvertisementPage",
  "/system/web/notice/listNoticePage",
  "/system/web/dictHead/selectDictDetail",
  "/system/web/role/getPageList",
  "/system/web/sms/listSmsSendPage",
  "/system/web/sms/listSmsTemplatePage",
  "/card/web/cardCustomer/getCardCustomerInfo",
  "/system/web/OfficialGoods/queryPageOfficialGoods",
  "/system/web/OfficialGoods/getOfficialGoods",
  "/system/web/health-care-info/getPageList",
  "/system/web/health-care-info/getHealthCareInfoById",
  "/system/web/healthCareType/selectHealthCareTypeById",
  "/card/web/localCard/listLocalCardPage",
  "/card/web/localCard/searchCardBalanceChange",
  "/card/web/localCard/exportLocalCard",
  "/tpa/web/tpaReview/selectTpaReviewPageList",//复核-分页
  "/tpa/web/tpaReview/selectTpaReviewDetail",//复核-详情
  "/tpa/web/tpaReview/selectTpaReviewDetailHistory",//复核-理赔历史
  "/tpa/web/tpaReview/showAllTpaReview",//复核-同步审核
  "/tpa/web/tpaReview/showTpaReview",//复核-导入
  "/tpa/web/tpaReview/passReview",//复核-单个审核通过
  "/tpa/web/tpaReview/getTpaCustomerImgList",//复核-查看影像件
  "/tpa//web/tpaReview/refuseReview",//复核-单个审核不通过
  "/tpa/web/tpaReview/updateDetailById",//复核-修改结论描述
  "/tpa/web/tpaReview/selectTpaReviewDetailDeleteHistory",//复核
  "/tpa/web/tpaReview/selectTpaReportView",//复核-报表预览
  "/tpa/web/tpaReview/exportTKTpaReviewInfo",//复核-导出
  "/tpa/web/tpaReview/batchPassReview",//复核-批量审核通过
  "/tpa/web/qTpaReview/listQTpaReviewCasePage",////企健TPA-复核
  "/tpa/web/qTpaReview/caseReviewUpdate",
  "tpa/web/qTpaReview/caseReviewFail",
  "/tpa/web/qTpaReview/caseReview",
  "/tpa/web/qTpaReview/batchCaseReview",
  "/tpa/web/qTpaReview/exportFreezeAmount",
  "/tpa/web/qTpaCaseEnter/queryCaseImgList",
  "/tpa/web/qTpaCaseEnter/queryCaseInvoiceDetail",
  "/system/web/bi/getBIToken",//数据看板
]

export default loadingRequests;
