import React, { useState, memo, useEffect, useRef } from 'react'
import renderRoutes from "@/routes/renderRoutes"
// antd
import { ConfigProvider, Layout, Modal } from 'antd'
import {
  Menu,
  Nav
} from '@/app/Layout'

import { withRouter, useHistory } from "react-router-dom"
import Icon, { LeftOutlined, RightOutlined } from '@ant-design/icons'
// redux
import store from '@/store/index'
import { Provider, useSelector, useDispatch } from 'react-redux'
import { updateCollapsed } from '@/store/actions'

// utils
import { Storage, Utils, LoadingRef } from '@/utils'
import loginModel from '@/model/Login/loginModel'
import LoadingAlert from '@/components/LoadingAlert';

import './BasicLayout.less'

// life
const headerRs = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/headerRS.png";
//tk
const headerTk = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/headerTk.png";
//qj
const headerQj = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/headerQj.jpg";

const { Sider } = Layout

const BasicLayout = ({ route }) => {
  const loadingRef = useRef();
  const history = useHistory()
  const dispatch = useDispatch()

  const sideBarProps = {
    collapsed: useSelector(state => state.sideBarCollapsed),
    onTriggerCollapsed: (_collapsed) => {
      console.log(_collapsed)
      dispatch(updateCollapsed(_collapsed))
    },
  }

  const [loginType, setLoginType] = useState('')

  useEffect(() =>{
    loginModel.userLoginExpire().then(({message}) => {
      if(!message){
        Modal.warning({
          title: '登录超时',
          content: '请重新登录',
          okText: '确认',
          onOk: ()=> {
            history.replace('/')
          }
        })
      }
    }).catch(err => {
      history.replace('/')
    })
  },[history])

  useEffect(() => {
    let userName = Storage.getStorage('userName')
    if (typeof userName === 'boolean') userName = ''
    // setUserName(userName)
    setLoginType(Storage.getStorage('loginType'))
  }, [])

  useEffect(() => {
    LoadingRef.setRef(loadingRef)
  }, [loadingRef])

  return (
    <ConfigProvider componentSize="middle">
      <div className='header-img'>
        <img alt="头部标题图片" src={Utils.environmentTypeFun() === 'TK' ? headerTk : Utils.environmentTypeFun() === 'LIFE' ? headerRs :  headerQj } />
      </div>
      <Nav loginType={loginType} />
      <Provider store={store}>
        <Layout style={{ minHeight: '100vh' }} className="basic-layout__container">
        <div style={{position:"relative"}}>
          <div className="transition" style={{display:sideBarProps.collapsed?"none":"block"}}>
            <Sider collapsed={sideBarProps.collapsed}>
              <Menu loginType={loginType}/>
            </Sider>
          </div>
          <div className="icon__collapsed" onClick={sideBarProps.onTriggerCollapsed.bind(this, sideBarProps.collapsed)}>
            <Icon component={sideBarProps.collapsed ? RightOutlined : LeftOutlined } />
          </div>
        </div>
          <Layout className="site-layout">
            <div id="content">
              <div className="site-layout__box">
                {renderRoutes(route.routes)}
              </div>
            </div>
          </Layout>
        </Layout>

        <LoadingAlert ref={loadingRef} />
      </Provider>
    </ConfigProvider>
  )
}

export default memo(withRouter(BasicLayout))
