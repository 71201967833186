import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
//引入antd
import { Form, Input, Modal, Col, Row, Button } from "antd";
//引入Utils公共组件
import { Rules, Utils, Storage, OPTIONS_FORM_SEARCH } from "@/utils";
import loginModel from "@/model/Login/loginModel";
import UserHomeModel from "@/model/UserHome/UserHomeModel";

import "./BindingPhone.less";

// life
const loginHeaderRs = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/headerRS.png";
//tk
const loginHeaderTk = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/headerTk.png";
//qj
const loginHeaderQj = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/headerQj.jpg";

const BindingPhone = props => {
  const history = useHistory();
  const [form] = Form.useForm();
  //校验图片
  const [checkImg, setCheckImg] = useState("");
  const [countDownStatus, setCountDownStatus] = useState(true);
  const [time, setTime] = useState(59);

  // //获取校验img
  const getCheckImg = () => {
    loginModel
      .getCheckImg()
      .then(({ datas }) => {
        setCheckImg(datas);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getSMS = () => {
    console.log(form.getFieldValue());
    if (!form.getFieldValue().code || form.getFieldValue().code === "")
      return Utils.warnMsg({ title: "请填写图片验证码！" });
    if (
      !form.getFieldValue().mobilePhone ||
      form.getFieldValue().mobilePhone === ""
    )
      return Utils.warnMsg({ title: "请填写手机号！" });
    if (countDownStatus) {
      // setCountDownStatus(false);
      let options = {
        imgCaptchaCode: form.getFieldValue().code,
        imgUuid: checkImg.uuid,
        insuranceCompanyFlag: Utils.insuranceCompanyFlagFun(),
        mobile: form.getFieldValue().mobilePhone
      };
      loginModel
        .sendSmsCaptcha({ data: options })
        .then(res => {
          console.log(res);
          if (res.success === true) {
            setCountDownStatus(false);
            let active = setInterval(() => {
              setTime(preSecond => {
                if (preSecond <= 0) {
                  setCountDownStatus(true);
                  clearInterval(active);
                  // 重置秒数
                  return 59;
                }
                return preSecond - 1;
              });
            }, 1000);
          }
        })
        .catch(err => getCheckImg());
    }
  };

  const getFormData = () => {
    let options = form.getFieldsValue();
    options = {
      ...options,
      insuranceCustomerId: Storage.getStorage("userInfo").insuranceCustomerId
    };
    console.log(options);
    // if (options.loginPassword !== options.confirmLoginPassword) {
    //   Utils.errorMsg({ title: "两次输入密码不一致，请重新输入！" });
    //   return false;
    // }
    UserHomeModel.bindMobilePhone({ data: options })
      .then(res => {
        if (res.message === "success") {
          Modal.success({
            title: "绑定手机号",
            content: "手机号绑定成功，即将返回首页！",
            okText: "确认",
            keyboard: false,
            onOk: () => {
              history.replace("/user/home");
              // window.localStorage.clear();
            }
          });
          // Modal.success({
          //   title: "绑定成功",
          //   content: "请尝试再次登录。",
          //   okText: "确认",
          //   onOk: () => {
          //     history.replace("/user/name");
          //   }
          // });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCheckImg();
  }, []);
  return (
    <div className="login-change-password-section">
      <div className="login-header">
        <img
          className="login-header-img"
          alt="headerImg"
          src={Utils.environmentTypeFun() === 'TK' ? loginHeaderTk : Utils.environmentTypeFun() === 'LIFE' ? loginHeaderRs : loginHeaderQj }
        />
      </div>
      <div className="card-status">
        <div className="card-statu-title">绑定手机号</div>
        <div className="card-activation">
          <Form
            name="statistical-card-consumption"
            form={form}
            {...OPTIONS_FORM_SEARCH}
            onFinish={getFormData}
          >
            <Row align="middle" justify="center" gutter={24}>
              <Col span={16}>
                <Form.Item
                  label="卡号"
                  className="input-item"
                  rules={Rules.ruleCommon("卡号")}
                >
                  <Input
                    type="text"
                    value={Storage.getStorage("userInfo").medcardNo}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" justify="center" gutter={24}>
              <Col span={16}>
                <Form.Item
                  label="证件号码"
                  className="input-item"
                  name="certificateNo"
                  rules={Rules.ruleCommon("证件号")}
                >
                  <Input type="text" placeholder="请输入证件号码" />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" justify="center" gutter={24}>
              <Col span={16}>
                <Form.Item
                  label="绑定手机号"
                  className="input-item"
                  name="mobilePhone"
                  rules={Rules.ruleCommon("手机号")}
                >
                  <Input type="text" placeholder="请输入手机号码" />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" justify="center" gutter={24}>
              <Col span={16}>
                <Form.Item label="验证码" className="flex-item">
                  <Form.Item
                    name="code"
                    className="flex-item-input"
                    rules={Rules.ruleCommon("图片验证码")}
                  >
                    <Input type="text" placeholder="请输入图片验证码" />
                  </Form.Item>
                  <div className="checkImg">
                    <img
                      alt="验证码"
                      className="Img"
                      src={checkImg.encodedText}
                      onClick={getCheckImg}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" justify="center" gutter={24}>
              <Col span={16}>
                <Form.Item label="短信验证码" className="flex-item">
                  <Form.Item
                    name="smsCaptchaCode"
                    className="flex-item-input"
                    rules={Rules.ruleCommon("短信验证码")}
                  >
                    <Input placeholder="请输入短信验证码" />
                  </Form.Item>
                  <Button className="yzBtn" type="primary" onClick={getSMS}>
                    {countDownStatus ? "获取验证码" : `${time}秒`}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" justify="center" gutter={24}>
              <Col span={11}>
                <Button
                  className="password-btn"
                  style={{ margin: "0 8px" }}
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  重置
                </Button>
                <Button
                  className="password-btn"
                  type="primary"
                  htmlType="submit"
                >
                  提交
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BindingPhone;
