import React, { memo } from 'react'
// antd
import { withRouter, useHistory, Link } from 'react-router-dom'
// utils
import { Utils, Storage, } from "@/utils";
import loginModel from '@/model/Login/loginModel'
//less
import './Nav.less'
// common img
import imgHome from '@/assets/home.png'
import imgLogout from '@/assets/logout.png'
import imgPhone from '@/assets/phone.png'
import imgHelp from '@/assets/help.png'

const Nav = ({loginType}) => {

    const history = useHistory()

    const LogOut = () => {
      loginModel.userLogout().then(res => {
        window.localStorage.clear()
        window.localStorage.href = "/login";
        window.location.reload()
        // history.push('/login');
      }).catch(err => {
        history.replace('/')
      })
    }

    const handleHelp = () => {
      /* tk:true  gs:false 
        loginType  1后台端，2保险端，3投保企业端，4商户/企健付端, 6持卡人端
      */
      let urlType = "";
      if(Utils.environmentTypeFun() === 'TK'){//泰康端
        switch (Storage.getStorage("loginType")) {
          case '2':
            urlType = "TK-insurance";
            break;
          case '3':
            urlType = "TK-company";
            break;
          case '6':
            urlType = "TK-person";
            break;
          default:
            break;
        }
      }else if(Utils.environmentTypeFun() === 'LIFE'){//国寿端
        switch (Storage.getStorage("loginType")) {
          case '2':
            urlType = "GS-insurance";
            break;
          case '3':
            urlType = "GS-company";
            break;
          case '6':
            urlType = "GS-person";
            break;
          default:
            break;
        }
      }else if(Utils.environmentTypeFun() === 'QJ'){//国寿端
        switch (Storage.getStorage("loginType")) {
          case '2':
            urlType = "QJ-insurance";
            break;
          case '3':
            urlType = "QJ-company";
            break;
          case '6':
            urlType = "QJ-person";
            break;
          default:
            break;
        }
      }
      const HelpUrl = "https://pbm-static-prod-1301342743.cos.ap-shanghai.myqcloud.com/"+ urlType +".pdf";
      window.open(HelpUrl);
    };
  
    return (
        <div className="wrap__header-nav">
          <div className='nav__heder-area'>
            <Link to={'/insurance/home'}>
              <div className="nav__home">
                  <img className="nav__home-img" src={imgHome} alt="首页的图标" />
                  首页
              </div>
            </Link>
            <div className="nav__logo">{loginType === '3' ? '企业账户' : '保险公司账户'}</div>
          </div>
          <div className="nav__detail">
            <div className="nav__detail--right">
              <span onClick={handleHelp}>
                <img className="nav__detail-img" src={imgHelp} alt="帮助中心" />
                帮助中心
              </span>
              <span><img className="nav__detail-img" src={imgPhone} alt="联系我们的图标" />联系我们： {Utils.environmentTypeFun() === 'TK' ? "400-66-95522" : Utils.environmentTypeFun() === 'LIFE' ?"400-812-6880" : "400-00-96001"}</span>
              <span  onClick={LogOut} ><img className="nav__detail-img" src={imgLogout} alt="安全退出的图标" />安全退出</span>
            </div>
          </div>
        </div>
    )
  }
  
export default memo(withRouter(Nav))
  